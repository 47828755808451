<template>
  <content justify="center">
    <v-dialog persistent v-model="modalResultados" max-width="900">
      <div class="text-center">
        <v-snackbar
          v-model="snackbarCorreo"
          :timeout="snackbarCorreoTimeout"
          absolute
          top
          color="info"
        >
          <h3>{{ textoSnackbarCorreo }}</h3>

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <v-card>
        <v-card-title class="info lighten-1 white--text">
          <strong>RESULTADO</strong>
          <v-progress-circular
            class="ml-2"
            indeterminate
            :color="colorProgreso"
          ></v-progress-circular>
        </v-card-title>

        <div class="container">
          <template>
            <v-progress-linear
              v-if="loadingUpdate"
              color="light-blue"
              height="20"
              v-bind:value="progressValue"
              striped
            ></v-progress-linear>
          </template>
          <v-row>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Exitosos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in itemsExitosos"
                      :key="item.orderIdExitoso"
                    >
                      <td>{{ item.orderIdExitoso }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="2">Fallidos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in itemsFallidos"
                      :key="item.orderIdFallido"
                    >
                      <td>{{ item.orderIdFallido }}</td>
                      <td>{{ item.motivo }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>

        <!-- {{ this.resultadosApi }} -->

        <!-- <v-row class="justify-center">
          <v-col sm="7" md="7" lg="7"> </v-col>
        </v-row> -->
        <v-card-actions class="justify-center">
          <v-btn color="info" @click="modalResultados = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="modalUpdates" max-width="900">
      <v-card class="pa-4">
        <v-card-text class="text-center pa-5"
          >ACTUALIZACIÓN DE TAREAS</v-card-text
        >
        <v-card-text class="text-center">
          <div>
            <v-icon size="60" color="blue lighten-2"
              >mdi-package-variant</v-icon
            >
          </div>
        </v-card-text>

        <v-col sm="7" md="5" lg="4">
          <v-datetime-picker
            label="SELECTOR DE FECHAS"
            v-model="datetimePicker"
            @input="cambiaDatetime()"
          >
          </v-datetime-picker>
        </v-col>
        <p>
          Ya sea selecciones las filas de pickups o delivery los cambios se
          aplicarán a ambas debido a que las tareas en FM se encuentran
          linkeadas
        </p>
        <p class="green darken--2">PICKUPS</p>
        <v-data-table
          rowsPerPage:-1
          v-model="modelPickups"
          :items="tasksArray.pickups"
          :headers="headersUpdate"
          item-key="job_id"
          show-select
        >
        </v-data-table>

        <p class="red darken--2">DELIVERIES</p>
        <v-data-table
          rowsPerPage:-1
          show-select
          v-model="modelDeliveries"
          item-key="job_id"
          :items="tasksArray.deliveries"
          :headers="headersUpdate"
        >
        </v-data-table>

        <v-card-text class="text-center"
          >¿Confirmas que deseas asignar a Rayo Virtual y/o cambiar
          fecha?</v-card-text
        >

        <v-row class="justify-center">
          <v-col sm="7" md="7" lg="7"> </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col sm="7" md="7" lg="7">
            <v-autocomplete
              :items="rayosVirtuales"
              item-value="id"
              v-model="rayoVirtual"
              item-text="text"
              label="Selecciona Rayo"
              solo
              @change="cambioRayoVirtual"
              return-object
              :rules="[(v) => !!v || 'Debes seleccionar Rayo Virtual']"
            ></v-autocomplete>

            <v-progress-circular
              indeterminate
              v-if="loadUpdate"
              color="green"
            ></v-progress-circular>
            <!-- <div>Resultado Actualización: {{ resultUpdate }}</div> -->
            <div>Resultado Registro SQL: {{ resultMDB }}</div>
          </v-col>
        </v-row>

        <v-card-actions class="justify-center">
          <v-btn color="info" @click="limpiaFields">Cerrar</v-btn>
          <v-btn color="green" @click="updateMasivo()">CONFIRMAR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo Resumen -->
    <v-dialog
      persistent
      v-model="modalResume"
      max-width="900"
      @keydown.esc="modalResume = false"
    >
      <v-card class="pa-4">
        <v-card-text class="text-center pa-5"
          >Resumen de Tareas para la creación de ruta</v-card-text
        >
        <v-card-text class="text-center">
          <div>
            <v-icon size="60" color="blue lighten-2"
              >mdi-package-variant</v-icon
            >
          </div>
        </v-card-text>

        <p class="green darken--2">Órdenes Válidas</p>
        <v-data-table
          rowsPerPage:-1
          v-model="modelPickups"
          :items="tasksArray.pickups"
          :headers="headersUpdate"
          item-key="job_id"
        >
        </v-data-table>

        <p class="red darken--2">Órdenes No Válidos</p>
        <v-data-table
          rowsPerPage:-1
          v-model="modelDeliveries"
          item-key="job_id"
          :items="tasksArray.deliveries"
          :headers="headersUpdate"
        >
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- Dialogo de mensaje -->
    <v-dialog
      v-model="modalMessage"
      persistent
      width="700"
      @keydown.esc="modalMessage = false"
    >
      <v-card>
        <v-card-title class="headline primary white--text py-1" primary-title>
          Mensaje
        </v-card-title>
        <p></p>
        <v-divider></v-divider>
        <p></p>

        <v-card-text>
          <strong> {{ message }}</strong>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" class="white--text" @click="modalMessage = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      @keydown="insertaControl()"
      persistent
      v-model="modalStatus"
      max-width="700"
    >
      <v-card-text ref="inserta" class="text-center"
        >RETIRO DE PEDIDOS</v-card-text
      >
      <v-card-text class="text-center">
        <div>
          <v-icon size="130" color="purple">mdi-package-variant</v-icon>
        </div>
      </v-card-text>
      <v-card-text class="text-center">
        (Presiona una tecla para confirmar)
      </v-card-text>
      <v-card-text class="text-center"
        >¿Confirmas que el pedido está listo para su retiro?</v-card-text
      >

      <v-row class="justify-center">
        <v-col sm="7" md="7" lg="7"> </v-col>
      </v-row>
      <v-card-actions class="justify-center">
        <v-btn color="info" @click="modalStatus = false">Cerrar</v-btn>
        <v-btn color="green" @click="insertaControl()"
          >CONFIRMAR (presiona una tecla)
        </v-btn>
      </v-card-actions>
    </v-dialog>

    <v-dialog v-model="dialogoMapa" width="100%">
      <v-lazy class="pa-3 ma-3">
        <v-card>
          <v-card-title class="text-h5 info lighten-1 white--text">
            Mapa
          </v-card-title>

          <l-map
            ref="myMap"
            style="height: 550px"
            :zoom="zoom"
            :center="center"
          >
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker
              v-for="(m, index) in markersDialogo"
              v-bind:key="index + 'steps'"
              :icon="iconURL('job', index + 1, colorMarkers).icono"
              :bgPos="ancla"
              :iconAnchor="ancla"
              :iconSize="iconSize"
              :lat-lng="m"
            ></l-marker>
            <l-polyline
              :lat-lngs="arrayCoordenadas"
              :color="colorLinea"
            ></l-polyline>

            <!-- <l-map ref="myMap" style="height: 350px" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
         -->
          </l-map>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="dialogoMapa = false"> Cerrar </v-btn>
          </v-card-actions>

          <!-- </l-map> -->

          <!-- <v-divider></v-divider>

    
         -->
        </v-card>
      </v-lazy>
    </v-dialog>

    <v-form ref="enviarFormulario" v-model="validar">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="7" lg="7" xl="7">
          <v-row class="justify-center">
            <v-icon size="100" color="info">mdi-account-arrow-right</v-icon>
          </v-row>
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7"> </v-col>
          </v-row>
          <!-- <v-row class="justify-center">
               <v-col sm="8" md="7" lg="7">
                    <v-select :items="tipos" label="Tipo de Operación" solo @change="cambio" :rules="[(v) => !!v || 'Debes seleccionar tipo de operación']"></v-select>
                </v-col>
                <v-col v-if="1==2" cols="2" sm="4" md="4" lg="4">                  
                    <v-switch v-if="displayVoluminoso" v-model="isVoluminoso" :label="`Voluminoso: ${isVoluminoso ? 'Sí' : 'No'}`" color="#1baefc" inset></v-switch>
                    <v-select v-if="displayFranjaHora" :items="franjaItems" label="Franja Hora" v-model="franjaHora" :rules="[(v) => !!v || 'Debes seleccionar una franja']" solo></v-select>
                </v-col>
            </v-row> -->
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                :items="rayosVirtuales"
                item-value="id"
                v-model="rayoVirtual"
                item-text="text"
                label="Selecciona Rayo"
                solo
                @change="cambioRayoVirtual"
                return-object
                :rules="[(v) => !!v || 'Debes seleccionar Rayo']"
                :loading="loading"
              ></v-autocomplete>

              <!-- <v-autocomplete
                :items="locales"
                v-model="localSeleccionado"
                label="Selecciona local"
                solo
                return-object
                :rules="[(v) => !!v || 'Debes seleccionar local']"
              ></v-autocomplete> -->

              <v-autocomplete
                :items="tiposOperacion"
                item-value="id"
                v-model="tipoOperacion"
                item-text="text"
                label="Selecciona acción"
                solo
                @change="cambioTipoOperacion"
                return-object
                :rules="[(v) => !!v || 'Debes seleccionar tipo de acción']"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- <v-row class="justify-center">
                <v-col cols="10" sm="10" md="10" lg="10">
                    <v-text-field v-if="(tipo != 'Asignacion' && tipo != 'Ingreso a Bodega') && tipo != 'Carga vehiculo'" filled background-color="purple" class="float-center" v-model="searchOrderId" v-on:keyup.enter="insertaControl()" @click:append="insertaControl()" append-icon="mdi-file" label="Escanea el ID del pedido listo para retiro" single-line color="purple" ref="buscador" outlined hide-details></v-text-field>
                </v-col>
            </v-row> -->
        </v-col>
      </v-row>
    </v-form>

    <!-- <v-row>
      <v-col cols="12" sm="8" md="6" lg="6"> </v-col>
    </v-row> -->

    <v-row class="justify-center" v-if="mostrarCargaVehiculo">
      <v-col sm="7" md="7" lg="7">
        <p>Rayo Virtual</p>
        <v-autocomplete
          multiple
          :items="rayosVirtuales"
          item-value="id"
          v-model="rayoVirtualCarga"
          item-text="text"
          label="Selecciona Rayo Virtual"
          solo
          :rules="[(v) => !!v || 'Debes seleccionar Rayo']"
        ></v-autocomplete>
        <p>Identifica al Vehículo</p>
        <v-select
          :items="vehiculos"
          item-value="id"
          v-model="vehiculo"
          item-text="text"
          label="Selecciona Rayo Virtual"
          solo
          :rules="[(v) => !!v || 'Debes identificar al Vehículo']"
        ></v-select>

        <p>Hora del día para registrar la carga al vehículo</p>
        <v-select
          :items="horasdia"
          item-value="id"
          v-model="horadia"
          item-text="text"
          label="Selecciona la hora"
          solo
          :rules="[(v) => !!v || 'Debes seleccionar la hora']"
        ></v-select>

        <v-btn @click="cargarVehiculo" color="green" class="ma-3">
          Ejecutar Carga de Vehículo
        </v-btn>

        <p>Resultado SQL: {{ resultCargaVehiculo }}</p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="8" md="5" lg="7">
        <v-textarea
          v-model="bandeja"
          v-if="mostrarAsignacion"
          outlined
          name="input-7-4"
          label=""
          value=""
        ></v-textarea>
        <v-btn
          v-if="mostrarAsignacion"
          @click="asignacionGo"
          color="green"
          class="ma-3"
        >
          Ejecutar Proceso de Asignación
        </v-btn>
        <v-btn color="teal" v-if="mostrarAsignacion" @click="limpiaBandeja">
          Borrar listado
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="mostratWH1">
      <v-col cols="12" sm="8" md="5" lg="7">
        <v-textarea
          ref="buscador"
          v-model="bandeja"
          outlined
          name="input-7-4"
          label=""
          value=""
        ></v-textarea>
        <v-row justify="center">
          <v-btn
            @click="asignarPedidos()"
            color="success"
            :loading="loading"
            :disabled="!validar"
          >
            Ejecutar
          </v-btn>
          <div class="text-center px-3 py-0 ma-0">
            <v-btn
              class="ml-4"
              color="success"
              :disabled="!validarRuta"
              @click="crearMapa(routePreview.polyline, routePreview.steps)"
            >
              Ver Mapa
              <v-icon color="#311eee"> mdi-map </v-icon>
            </v-btn>
          </div>

          <v-btn
            class="ml-4 white--text"
            color="blue"
            :loading="loadingRoute"
            :disabled="!validarRuta"
            @click="createRoute()"
          >
            Crear Ruta
          </v-btn>
          <div class="text-center px-3 py-0 ma-0">
            <v-btn
              class="ml-4 white--text"
              color="blue"
              :loading="loadingRoute"
              :disabled="!validateLog"
              @click="modalMessage = true"
            >
              Logs
              <v-icon color="#311eee">mdi-playlist-check</v-icon>
            </v-btn>
          </div>
          <v-btn class="ml-4" color="warning" @click="limpiaBandeja">
            Limpiar
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <!-- <v-row v-if="!mostrarAsignacion  && !mostrarCargaVehiculo" class="mx-9">
        <v-col>
            <v-data-table rowsPerPage:-1 :headers="headersScanner" :items="datosScanner" :sort-by="['uid']" multi-sort class="elevation-1"></v-data-table>
        </v-col>
    </v-row> -->
    <v-row v-if="!mostratWH1 && !mostrarCargaVehiculo" class="mx-9">
      <v-col>
        <v-data-table
          rowsPerPage:-1
          :headers="headersScanner"
          :items="datosScanner"
          :sort-by="['uid']"
          multi-sort
          class="elevation-2"
        ></v-data-table>
      </v-col>
    </v-row>

    <v-overlay v-model="showCargando">
      <v-progress-circular
        :size="100"
        :width="10"
        color="red"
        indeterminate
      ></v-progress-circular>
      <div>Procesando...</div>
    </v-overlay>
    <v-snackbar
      :timeout="timeoutSnack"
      class="black--text"
      color="orange"
      v-model="snackbar"
      >{{ snackText }}</v-snackbar
    >
  </content>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import { mapGetters } from "vuex";
import moment from "moment";
import Payments from "./Payments";
import LoginVue from "./Login.vue";
import polyUtil from "polyline-encoded";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPolyline } from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    Payments,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
  },
  data: () => ({
    snackbarCorreo: false,
    textoSnackbarCorreo: `¡Correo enviado!`,
    snackbarCorreoTimeout: 3000,
    locales: [
      "BODEGA RAYO",
      "159",
      "165",
      "179",
      "200",
      "214",
      "227",
      "367",
      "793",
      "859",
      "865",
      "1042",
      "1043",
      "1044",
      "1088",
      "1113",
      "1114",
      "1115",
      "1119",
      "1120",
      "1121",
      "1123",
      "1129",
    ],
    localSeleccionado: "",
    validar: false,
    validarRuta: false,
    rutaPreview: {},
    call: 1,
    modelDeliveries: [],
    modelPickups: [],
    horasdia: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24,
    ],
    horadia: 0,
    idProvider: "",
    vehiculo: "",
    vehiculos: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    // selectedRowsPickup: [],
    // selectedRowsDelivery: [],
    resultUpdate: "",
    resultCargaVehiculo: "",
    resultMDB: "",
    items: [],
    itemsFallidos: [],
    itemsExitosos: [],
    loadingUpdate: false,
    progressValue: 0,
    correoIngreso: "",
    colorProgreso: "",
    alertaAccion: false,
    //dev
    // api_key: "51666687f44503171a146e38414a254314e2cdfc2ada7a3c5c1c04",
    //prod
    api_key: "28c59a186592ea55f8849455f0a16933c19e39db3fde6303eacfcc81eff9f0aa",

    loadUpdate: false,

    datetimePicker: "",
    headersUpdate: [
      {
        text: "Order Id",
        align: "start",
        sortable: true,
        value: "order_id",
      },
      {
        text: "Job_id",
        align: "start",
        sortable: true,
        value: "job_id",
      },
      {
        text: "TIEMPO A UPDATEAR",
        align: "start",
        sortable: true,
        value: "time",
      },
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "job_status",
      },
      {
        text: "Tipo",
        align: "start",
        sortable: true,
        value: "job_type",
      },
      {
        text: "Hash",
        align: "start",
        sortable: true,
        value: "job_hash",
      },
      {
        text: "Rayo ID",
        align: "start",
        sortable: true,
        value: "fleet_id",
      },
      {
        text: "Rayo Name",
        align: "start",
        sortable: true,
        value: "fleet_name",
      },
      {
        text: "Id Thor Task",
        align: "start",
        sortable: true,
        value: "job_id_mdb",
      },
    ],

    tasksArray: { deliveries: [], pickups: [] },
    tasksArrayEnvio: { deliveries: [], pickups: [] },
    modalUpdates: false,
    modalMessage: false,
    message: "",
    modalResume: false,
    bandeja: "",
    bandejaArray: "",
    mostrarAsignacion: false,
    dialogoMapa: false,
    colorLinea: "#311DEF",
    arrayCoordenadas: null,
    url: "https://api.mapbox.com/styles/v1/rayoapp/ckhfarifi0bfq19t72qem6bh3/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmF5b2FwcCIsImEiOiJjanA0aW52aXowbjNvM3BzYW1tZDVyeGxzIn0.BHRdulbBe-zysvzUJwVvQw",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    center: [-33.4366174828686, -70.59275946636537],
    zoom: 10,
    ancla: [20, 45],
    iconSize: [40, 60],
    colorMarkers: "#0099cc",
    routePreview: {},
    mostrarCargaVehiculo: false,
    rayosVirtualesFetch: [],
    rayosVirtuales: [],
    stepsRuta: {},
    markersDialogo: [],
    statusesWMS: [],
    selectedStatusWMS: "",
    selectedStatus: "",
    estados: [],
    rayoVirtual: "",
    rayovirtual_name: "",
    rayoVirtualCarga: [],
    datosScanner: [],
    headersScanner: [
      {
        text: "UID",
        sortable: true,
        value: "uid",
      },
      {
        text: "EMAIL",
        sortable: true,
        value: "email",
      },
      {
        text: "ID_FCV",
        sortable: true,
        value: "id_fcv",
      },
      {
        text: "NOTAS",
        sortable: true,
        value: "notes",
      },
      {
        text: "VOLUMINOSO",
        sortable: true,
        value: "voluminoso",
      },
      {
        text: "TIPO",
        sortable: true,
        value: "tipo",
      },
      {
        text: "ESTADO",
        sortable: true,
        value: "estado",
      },
    ],
    voluminoso: false,
    vehiculo: "",
    notes: "",
    tipo: "",
    tipos: [
      "Ingreso a Bodega",
      "Escaneo en sucursal",
      "Devolucion en local",
      "Asignacion",
      "Pedido no listo para retiro",
      "Levantado por Chat",
    ],
    //tipos: ["Recepcion CD", "Escaneo en sucursal", "Asignacion", "Carga vehiculo"],
    timeoutSnack: 1500,
    resultadoGestionCC: [
      "Número no contesta",
      "Número equivocado",
      "Cliente solicita llamada en otro momento",
      "Agenda exitosa",
      "Cliente rechaza entrega",
    ],
    selectedResultadoGestionCC: "",
    modalResultados: false,
    resultadosApi: {},
    modalStatus: false,
    confirmaFechas: false,
    showCargando: false,
    ref: firebase.database().ref("clientes/FCV/"),
    // ref:firebase.database().ref("clientes/FCVDEV"),
    tsfin: 0,
    tsinicio: 0,
    tsfinReporte: 0,
    tsinicioReporte: 0,
    listener: null,
    ipp: 150,
    dataOptions: {
      headers: "headers",
      page: 1,
      itemsPerPage: 30,
      sortBy: "tiempoServidorGoogle",
      sortDesc: true,
      groupBy: "token_id",
      groupDesc: true,
      multiSort: true,
      mustSort: true,
    },
    modalDelete: false,
    modalCancel: false,
    dataDelete: "",
    dataCreate: "",
    searchOrderId: "",
    arrayOrder_ids: [],
    datosTask: {
      task_id: "",
      history: {},
    },
    //datepicker
    date: [],
    menu: false,

    //f datepicker
    pedidosTokenPath: "",
    snackbar: false,
    snackText: "",
    dialogoUpdate: false,
    selectedDT: "",

    cancelList: [
      "Pedido duplicado en sistema",
      "Falta de stock en sucursal",
      "Pedido no salió de sucursal",
      "Pedido anulado por cliente",
      "Fuera de cobertura",
      "Fuera de cobertura (Zona peligrosa)",
      "Prueba técnica",
    ],
    loadingData: false,

    selectedRows: [],
    resultadoQuery: {},
    reportData: [],
    tipoOperacion: "",
    tipoOperacionAux: "",
    tiposOperacion: [
      // "ASIGNACION PICKUPS",
      // "PICK UPS EXITOSOS",
      "ASIGNACION DELIVERIES",
    ],
    reportHeaders: [
      "CREACIÓN",
      "ID FCV",
      "NOMBRE",
      "STATUS",
      "DIRECCIÓN",
      "EJECUTIV@",
      "SUCURSAL",
      "PUSHKEY",
      "ORIGEN",
    ],
    mostratWH1: true,
    modalTracking: false,
    textoDialogoGenerico: "",
    tituloDialogoGenerico: "",
    currentLink: "",
    accionDialogoGenerico: "",
    destinoDialogoGenerico: "",
    dialogoGenerico: false,
    singleExpand: true,
    expanded: [],
    loading: false,
    loadingRoute: false,
    validateLog: false,

    search: "",
    headersAdmin: [
      {
        text: "Creación",
        align: "left",

        value: "creacionreal",
      },
      { text: "ID FCV", value: "id_fcv" },
      { text: "Pickup", value: "tookan1.pickups[0].job_id" },
      { text: "Delivery", value: "tookan1.deliveries[0].job_id" },
      { text: "Nombre", value: "deliveries[0].name" },
      { text: "Status", value: "tookan1.mainstatus.status" },
      { text: "Dirección", value: "deliveries[0].address" },
      { text: "Ejecutiv@", value: "deliveries[0].template_data[3].data" },
      { text: "Sucursal", value: "deliveries[0].template_data[10].data" },
      { text: "PushKey", value: "deliveries[0].template_data[11].data" },
      { text: "Origen", value: "deliveries[0].template_data[14].data" },

      { text: "Acciones", value: "action" },
      {
        text: "Causa Cancelación",
        value: "tookan1.mainstatus.causaCancelacion",
      },

      // { text: "AccionesDelete", value: "deleteAction" }
    ],
    headers: [
      {
        text: "Creación",
        align: "left",

        value: "tiempoServidorGoogle",
      },
      // { text: "Id", value: "tokenKey" },
      // { text: "Trayectos", value: "cuenta" },
      { text: "CECO", value: "ceco" },
      // { text: "UID", value: "uid" },
      { text: "Status", value: "generalStatus" },
      { text: "KMs", value: "km" },
      { text: "Precio", value: "precio" },
      { text: "Incluye Regreso", value: "incluyeVuelta" },
      { text: "Recogida", value: "trayectos.pickups[0]" },
      { text: "Entrega", value: "trayectos.deliveries[0]" },
      { text: "Link", value: "linkInicial" },

      { text: "Pago", value: "pagoPendiente" },
      { text: "Acciones", value: "action" },
    ],
    grupos: [
      { name: "1", calories: "2" },
      { name: "3", calories: "4" },
    ],
    drawer: null,
    markers: [1, 2],

    //Field condicional
    isVoluminoso: false,
    franjaHora: "",
    franjaItems: ["Retiro 1", "Retiro 2", "Retiro 3"],
  }),
  computed: {
    selectedOrderIds: function () {
      return this.modelPickups.concat(this.modelDeliveries);
    },
    displayVoluminoso: function () {
      return this.tipo === "Escaneo en sucursal" ? true : false;
    },
    displayFranjaHora: function () {
      return this.tipo === "Pedido no listo para retiro" ? true : false;
    },

    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    modalStatus() {
      if (this.modalStatus == true) {
        // modal mostrada
        console.log("watch");
        this.$nextTick().then(() => this.$refs.inserta.focus());
      } else {
        console.log("NO watch");
      }
    },
  },
  methods: {
    datosUsuario() {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid,
      };
    },
    validarFormulario() {
      this.$refs.enviarFormulario.validate();
    },
    limpiaFields() {
      this.modalUpdates = false;
      this.tasksArray["pickups"] = [];
      this.tasksArray["deliveries"] = [];
      this.resultUpdate = "";
      this.resultMDB = "";
      this.vehiculo = "";
      this.rayoVirtual = "";

      this.modelDeliveries = [];
      this.modelPickups = [];
    },
    limpiaBandeja() {
      this.bandeja = "";
      this.rayoVirtual = "";
      this.validarRuta = false;
    },
    cargarVehiculo() {
      self = this;
      if (
        this.vehiculo == "" ||
        this.rayoVirtualCarga.length == 0 ||
        this.horadia == 0
      ) {
        alert("Debes identificar al vehículo, hora de carga y  Rayo virtual");
      } else {
        console.log("Cargando vehículo");
        let objetoCarga = {
          uid: self.$store.state.user.data.uid,
          email: self.$store.state.user.data.email,
          order_id: "No aplica",
          job_id: "No aplica",
          job_type: 1,
          notes: "Carga vehículo",
          voluminoso: self.voluminoso,
          tipo: self.tipo,
          vehiculo: self.vehiculo,
          rayoVirtualCarga: self.rayoVirtualCarga,
          hora_carga: self.horadia,
          emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime(),
        };

        self.loadUpdate = true;
        axios
          .post(
            "https://us-central1-rayo-dev.cloudfunctions.net/retirosLocalSocofar/cargaVehiculo ",
            objetoCarga
          )
          .then(function (response) {
            self.resultCargaVehiculo = response.data;
          })
          .catch(function (error) {
            // handle error
            self.resultCargaVehiculo = error;
          });
      }
    },
    // selectedRowsPickupMethod (valor) {
    //   console.log(valor)
    //   this.selectedRowsPickup = []
    //   for (let x in valor) {
    //     this.selectedRowsPickup.push({ order_id: valor[x].order_id, job_type: valor[x].job_type, job_id: valor[x].job_id })
    //   }

    // },
    // selectedRowsDeliveryMethod (valor) {
    //   console.log(valor)
    //   this.selectedRowsDelivery = []
    //   for (let x in valor) {
    //     this.selectedRowsDelivery.push({ order_id: valor[x].order_id, job_type: valor[x].job_type, job_id: valor[x].job_id })
    //   }
    // },

    getName(fleetIdIn) {
      return this.rayosVirtuales
        .filter((item) => {
          return item.id === fleetIdIn;
        })
        .map((filteredObj) => filteredObj.text);
    },
    getStatusName(statusIdIn) {
      return this.estados
        .filter((item) => {
          return item._id === statusIdIn;
        })
        .map((filteredObj) => filteredObj.name_status);
    },

    state: function (xd) {
      switch (xd) {
        case 0:
          return "Asigned";
        case 1:
          return "Started";
        case 2:
          return "Successful";
        case 3:
          return "Failed";
        case 4:
          return "In Progress";
        case 6:
          return "Unassigned";
        case 7:
          return "Accepted";
        case 8:
          return "Decline";
        case 9:
          return "Cancel";
        case 10:
          return "Deleted";
      }
    },
    cambiaDatetime(v) {
      this.selectedDT = moment(this.datetimePicker).format(
        "YYYY-MM-DD HH:mm:00"
      );
      console.log(this.momentum(this.datetimePicker));
      if (
        this.momentum(this.datetimePicker).isBefore(this.momentum(new Date()))
      ) {
        alert("No puedes asignar a tiempo pasado");
        this.selectedDT = "";
      } else {
        for (let x in this.tasksArray) {
          for (let a in this.tasksArray[x]) {
            //console.log(this.tasksArray[x][a])
            this.tasksArray[x][a].time = moment(this.datetimePicker).format(
              "YYYY-MM-DD HH:mm:00"
            );
          }
        }
      }
    },
    async updateMasivo() {
      self = this;

      self.tasksArrayEnvio = JSON.parse(JSON.stringify(self.tasksArray));

      console.log(self.selectedOrderIds);
      console.log(self.tasksArray);

      var localOrderIds = [];
      for (let n in self.selectedOrderIds) {
        localOrderIds.push(self.selectedOrderIds[n].order_id);
      }

      // elimina los elementos que no corresponden a selección
      for (let x in self.tasksArrayEnvio["pickups"]) {
        console.log("pickup:" + x);

        if (
          !localOrderIds.includes(self.tasksArrayEnvio["pickups"][x].order_id)
        ) {
          console.log("rem");
          self.tasksArrayEnvio["pickups"].splice(x, 1);
          //    delete self.tasksArrayEnvio['pickups'][x]
        }
      }

      for (let x in self.tasksArrayEnvio["deliveries"]) {
        console.log("delivery:" + x);
        if (
          !localOrderIds.includes(
            self.tasksArrayEnvio["deliveries"][x].order_id
          )
        ) {
          console.log("rem delivery");
          self.tasksArrayEnvio["deliveries"].splice(x, 1);
          //    delete self.tasksArrayEnvio['deliveries'][x]
        }
      }

      for (var x in self.tasksArrayEnvio["pickups"]) {
        delete self.tasksArrayEnvio["pickups"][x].job_status;
        self.tasksArrayEnvio["pickups"][x].timezone = "-240";
      }

      for (var x in self.tasksArrayEnvio["deliveries"]) {
        delete self.tasksArrayEnvio["deliveries"][x].job_status;
        self.tasksArrayEnvio["deliveries"][x].timezone = "-240";
      }

      // final
      if (
        self.tasksArrayEnvio["pickups"] == undefined ||
        self.tasksArrayEnvio["pickups"].length == 0
      ) {
        delete self.tasksArrayEnvio["pickups"];
      }
      if (
        self.tasksArrayEnvio["deliveries"] == undefined ||
        self.tasksArrayEnvio["deliveries"].length == 0
      ) {
        delete self.tasksArrayEnvio["deliveries"];
      }

      // ff final
      // fff // elimina los elementos que no corresponden a selección

      console.log(self.tasksArray);
      console.log(self.tasksArrayEnvio);

      if (
        self.selectedOrderIds.length == 0 ||
        self.selectedOrderIds.length == undefined
      ) {
        alert("No has seleccionado las ordenes");
      } else if (this.rayoVirtual == "") {
        alert("Debes seleccionar un rayo");
      } else {
        console.log("Editando masivamente");
        self.loadUpdate = true;

        console.log(
          JSON.stringify({
            api_key: self.api_key,

            pickups: self.tasksArrayEnvio["pickups"],
            deliveries: self.tasksArrayEnvio["deliveries"],
          })
        );

        // remove status

        for (var x in self.modelPickups) {
          delete self.modelPickups[x].job_status;
        }

        for (var x in self.modelDeliveries) {
          delete self.modelDeliveries[x].job_status;
        }

        // remove status
        // genera arrreglo de ids a editar
        var arTasks = [];

        var timeCandidateP0 = "";
        var timeCandidateD0 = "";

        var timeSent = "";
        if (self.modelPickups.length > 0) {
          for (let b in self.modelPickups) {
            arTasks.push({ key: self.modelPickups[b].job_id_mdb });
            if (b == 0) {
              timeCandidateP0 = self.modelPickups[b].time;
            }
          }
        }

        if (self.modelDeliveries.length > 0) {
          for (let b in self.modelDeliveries) {
            arTasks.push({ key: self.modelDeliveries[b].job_id_mdb });

            if (b == 0) {
              timeCandidateD0 = self.modelDeliveries[b].time;
            }
          }
        }

        // FF GENERA ARREGLO
        if (self.selectedDT != "") {
          timeSent = self.selectedDT;
        } else if (timeCandidateP0 == "") {
          timeSent = timeCandidateD0;
        } else {
          timeSent = timeCandidateP0;
        }
        // ff genera fecha

        var enviando = {
          // api_key: self.api_key,

          // pickups: self.modelPickups,
          // deliveries: self.modelDeliveries,
          tasks: arTasks,
          idrayo_task: self.rayoVirtual,
          // date_task: timeSent,
          modifier_user: {
            name: "dataSococar",
            email: "datasocofar@rayoapp.com",
          },

          emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime(),
        };

        var enviandoDate = {
          // api_key: self.api_key,

          // pickups: self.modelPickups,
          // deliveries: self.modelDeliveries,
          tasks: arTasks,
          // idrayo_task: self.rayoVirtual,
          date_task: timeSent,
          modifier_user: {
            name: "dataSococar",
            email: "datasocofar@rayoapp.com",
          },
          emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime(),
        };

        console.log(enviando);
        console.log(JSON.stringify(enviando));
        axios
          // .post("https://api.tookanapp.com/v2/edit_multiple_tasks", enviando)
          .put(
            "https://backendservices.rayoapp.com/Tasks/tasks_rayo&rayo=08a46637120a404a963395dd86986b4f",
            enviando,
            {
              headers: {
                "warehouse.rayoapp.com": "rayo",
              },
            }
          )
          .then(function (response) {
            self.loadUpdate = false;
            console.log("EXITO UPDATE");
            console.log(response.data.result);
            self.resultUpdate = response.data.result;

            axios
              .put(
                "https://backendservices.rayoapp.com/Tasks/tasks_by&rayo=08a46637120a404a963395dd86986b4f",
                enviandoDate,
                {
                  headers: {
                    "warehouse.rayoapp.com": "rayo",
                  },
                }
              )
              .then(function (rrr) {
                console.log("update date ok");
                console.log(rrr.data);
                self.resultUpdate = rrr.data.result;
              });
            // update task status

            // for (let xx in arTasks) {
            //     axios.put("https://backendservices.rayoapp.com/Tasks/tasks_by", {
            //         key: arTasks[xx].key,
            //         idstatus_task: "5f05f3d37f5164494c74b967",
            //         modifier_user: {
            //             name: "Datasocofar",
            //             email: "datasocofar@rayoapp.com"
            //         }
            //     }).then(function (rrr) {
            //         console.log("update date ok")
            //         console.log(rrr.data)
            //         self.resultUpdate = rrr.data.result;
            //     })
            // }

            //ff  update task status

            let objetoOkMulti = {
              uid: self.$store.state.user.data.uid,
              email: self.$store.state.user.data.email,
              ids_fcv: self.selectedOrderIds,
              notes: self.notes,
              voluminoso: self.voluminoso,
              tipo: self.tipo,
              //vehiculo: self.vehiculo,
              rayo_virtual: self.rayoVirtual,
              rayo_virtual_name: self.rayovirtual_name,
              emailResponsable: this.datosUsuario().email_user,
              nameResponsable: this.datosUsuario().name_user,
              uidResponsable: this.datosUsuario().uid,
              sistemaResponsable: "warehouse.rayoapp.com",
              epochResponsable: new Date().getTime(),
            };

            self.loadUpdate = true;
            axios
              .post(
                "https://us-central1-rayo-dev.cloudfunctions.net/retirosLocalSocofar/multi ",
                objetoOkMulti
              )
              .then(function (response) {
                self.resultMDB = response.data;
                self.loadUpdate = false;
                //     alert("RESULTADO SQL:" + self.resultMDB   +"\n \n " + "RESULTADO UPDATE" + self.resultUpdate)
              })
              .catch(function (error) {
                // handle error
                self.resultMDB = error;
                self.loadUpdate = false;
              });
          })
          .catch(function (error) {
            // handle error
            self.loadUpdate = false;
            console.log(error);
            self.resultUpdate = error;
          });
      }
    },
    async asignacionGo() {
      this.showCargando = true;
      self = this;
      console.log("asignacion");
      this.bandejaArray = this.bandeja.split(/\r\n|\n|\r/);
      this.bandejaArray = this.bandejaArray.filter((e) => e !== ""); // will return ['A', 'C']

      function disparaCierre() {
        setTimeout(async function () {
          // llamada a FM
          // crea muchas promesas de axios

          try {
            var resMDB = await axios.post(
              "https://southamerica-east1-rayo-api.cloudfunctions.net/alternativeApisMongoDb/getTasksByOrderIds",
              {
                order_ids: self.bandejaArray,
                emailResponsable: this.datosUsuario().email_user,
                nameResponsable: this.datosUsuario().name_user,
                uidResponsable: this.datosUsuario().uid,
                sistemaResponsable: "warehouse.rayoapp.com",
                epochResponsable: new Date().getTime(),
              }
            );
            console.log(resMDB);
            if (resMDB.status == 202) {
              self.showCargando = false;
              alert("No se encuentran tareas en Thor para estos order_ids");
            }

            if (resMDB.status == 200) {
              for (let x in resMDB.data) {
                if (
                  resMDB.data[x].idstatus_task != "5f05f42e7f5164494c74b96e"
                ) {
                  switch (resMDB.data[x].type_task) {
                    case 1:
                      self.tasksArray.pickups.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });

                      break;
                    case 2:
                      self.tasksArray.deliveries.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });
                      break;
                    // }
                  }

                  if (x == resMDB.data.length - 1) {
                    console.log(self.tasksArray);
                    self.showCargando = false;
                    self.modalUpdates = true;
                  }
                } else {
                  alert(
                    "EXCLUYE ESTA ORDER_ID YA QUE ESTÁ ELIMINADA EN FM:" +
                      resMDB.data[x].idTasksGroup_task +
                      "    -        EL JOB_ID ES " +
                      resMDB.data[x].code_task
                  );
                  self.showCargando = false;
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }, 0);
      }

      async function updatea(thi, snapshotOK) {
        for (let xx in thi.bandejaArray) {
          for (let yy in snapshotOK) {
            console.log("xx:" + parseInt(xx));

            console.log("yy:" + parseInt(yy));
            console.log("sss");
            console.log(snapshotOK[parseInt(yy)]);

            if (
              snapshotOK[parseInt(yy)].val() != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].id_fcv != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].aux == thi.bandejaArray[parseInt(xx)]
            ) {
              console.log(
                "encuentra y asigna aux ok para:" +
                  thi.bandejaArray[parseInt(xx)]
              );
              thi.bandejaArray[parseInt(xx)] =
                snapshotOK[parseInt(yy)].val()[
                  Object.keys(snapshotOK[parseInt(yy)].val())[0]
                ].id_fcv;

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
              break;
            } else {
              console.log(
                "iteración y:" +
                  yy +
                  " encuentra null para:" +
                  thi.bandejaArray[parseInt(xx)]
              );

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
            }
          }
        }
      }

      function updatea2(posicionBandeja, valor) {}
      gogo(this);

      // ff acá vamos a consuiltar el aux y sobreescribimos el id_fcv
    },
    async asignacionGoWH1() {
      this.showCargando = true;
      self = this;
      console.log(this.tipo + " funcion go");
      this.bandejaArray = this.bandeja.split(/\r\n|\n|\r/);
      this.bandejaArray = this.bandejaArray.filter((e) => e !== ""); // will return ['A', 'C']
      console.log(this.bandejaArray);
      var objetoSent = {
        scans: this.bandejaArray,
        status: this.selectedStatusWMS._id,
        position: this.selectedStatusWMS.positionIdScan || null,
        rayoId: this.rayoVirtual,
        userEmail: this.user.data.email,
        userUid: this.user.data.uid,
        source: "scan en warehouse.rayoapp.com",
        emailResponsable: this.datosUsuario().email_user,
        nameResponsable: this.datosUsuario().name_user,
        uidResponsable: this.datosUsuario().uid,
        sistemaResponsable: "warehouse.rayoapp.com",
        epochResponsable: new Date().getTime(),
      };

      axios
        .post(
          "https://us-central1-rayo-api.cloudfunctions.net/mongoWarehouse/inserta",
          objetoSent
        )
        // .post("http://localhost:8080/inserta", objetoSent)

        .then(function (res) {
          console.log(res);
          self.resultMDB =
            res.data.insertedCount +
            " ===>" +
            JSON.stringify(res.data.insertedIds);
          self.showCargando = false;
        })
        .catch((e) => {
          console.log(e);
          self.resultMDB = e;
          self.showCargando = false;
        });

      //juan
      var promises = [];
      var superDuper = [];
      var snapshotOK = [];

      async function gogo(the) {
        for (var xx in the.bandejaArray) {
          //superDuper.push(the.ref.child("pedidosDEV")
          superDuper.push(
            the.ref
              .child("pedidos")
              .orderByChild("aux")
              .limitToLast(1)
              .equalTo(the.bandejaArray[xx])
          );
          snapshotOK.push(await superDuper[xx].once("value"));

          if (xx == the.bandejaArray.length - 1) {
            console.log("fin loop");
            updatea(the, snapshotOK);
          }
        }
      }

      function disparaCierre() {
        setTimeout(async function () {
          // llamada a FM
          // crea muchas promesas de axios

          try {
            var resMDB = await axios.post(
              "https://southamerica-east1-rayo-api.cloudfunctions.net/alternativeApisMongoDb/getTasksByOrderIds",
              {
                order_ids: self.bandejaArray,
              }
            );
            console.log(resMDB);
            if (resMDB.status == 202) {
              self.showCargando = false;
              alert("No se encuentran tareas en Thor para estos order_ids");
            }

            if (resMDB.status == 200) {
              for (let x in resMDB.data) {
                if (
                  resMDB.data[x].idstatus_task != "5f05f42e7f5164494c74b96e"
                ) {
                  switch (resMDB.data[x].type_task) {
                    case 1:
                      self.tasksArray.pickups.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });

                      break;
                    case 2:
                      self.tasksArray.deliveries.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });
                      break;
                    // }
                  }

                  if (x == resMDB.data.length - 1) {
                    console.log(self.tasksArray);
                    self.showCargando = false;
                    self.modalUpdates = true;
                  }
                } else {
                  alert(
                    "EXCLUYE ESTA ORDER_ID YA QUE ESTÁ ELIMINADA EN FM:" +
                      resMDB.data[x].idTasksGroup_task +
                      "    -        EL JOB_ID ES " +
                      resMDB.data[x].code_task
                  );
                  self.showCargando = false;
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }, 0);
      }

      async function updatea(thi, snapshotOK) {
        for (let xx in thi.bandejaArray) {
          for (let yy in snapshotOK) {
            console.log("xx:" + parseInt(xx));

            console.log("yy:" + parseInt(yy));
            console.log("sss");
            console.log(snapshotOK[parseInt(yy)]);

            if (
              snapshotOK[parseInt(yy)].val() != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].id_fcv != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].aux == thi.bandejaArray[parseInt(xx)]
            ) {
              console.log(
                "encuentra y asigna aux ok para:" +
                  thi.bandejaArray[parseInt(xx)]
              );
              thi.bandejaArray[parseInt(xx)] =
                snapshotOK[parseInt(yy)].val()[
                  Object.keys(snapshotOK[parseInt(yy)].val())[0]
                ].id_fcv;

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
              break;
            } else {
              console.log(
                "iteración y:" +
                  yy +
                  " encuentra null para:" +
                  thi.bandejaArray[parseInt(xx)]
              );

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
            }
          }
        }
      }

      function updatea2(posicionBandeja, valor) {}
      //   gogo(this)

      // ff acá vamos a consuiltar el aux y sobreescribimos el id_fcv
    },
    accionOK() {
      this.showCargando = true;
      var self = this;
      console.log(this.tipo + " funcion go");
      this.bandejaArray = this.bandeja.split(/\r\n|\n|\r/);
      this.bandejaArray = this.bandejaArray.filter((e) => e !== ""); // will return ['A', 'C']
      console.log(this.bandejaArray);
      var objetoSent = {
        scans: this.bandejaArray,
        status: this.selectedStatusWMS._id,
        position: this.selectedStatusWMS.positionIdScan || null,
        rayoId: this.rayoVirtual,
        userEmail: this.user.data.email,
        userUid: this.user.data.uid,
        source: "scan en warehouse.rayoapp.com",
      };
      console.log(objetoSent);

      this.call = 1;
      for (var x in this.bandejaArray) {
        self.safeCall(this.bandejaArray[x], x, this.bandejaArray.length - 1);
        //  if(x== this.bandejaArray.length-1) {
        //   this.showCargando = false;
        //  }
      }

      // f safecall
    },
    safeCall(datoUnitario, posicion, total) {
      var that = this;
      // safecall

      this.call++;
      var x = this.call * 200;
      //alert(x);
      setTimeout(function () {
        that.safeCallUnitaria(datoUnitario);

        if (posicion == total - 1) {
          that.showCargando = false;
        }
      }, x);
    },
    safeCallUnitaria(datosIn) {
      console.log(datosIn);
      let dataSent = {
        idtask: "",
        order_ids: [],
        idrayo: this.rayoVirtual,
        multiscan_type: "",
      };

      //   axios
      //     .post("CONSIGUE TASK_ID ", dataSent)
      //     .then(function (response) {})
      //     .catch(function (err) {
      //       console.log(err);
      //       console.log("error");
      //     });
    },
    async asignarPedidos() {
      let that = this;
      this.validateLog = false;
      this.validarRuta = false;
      this.bandejaArray = this.bandeja.split(/\r\n|\n|\r/);
      this.bandejaArray = this.bandejaArray.filter((e) => e !== ""); // will return ['A', 'C']
      let arregloTask = this.bandejaArray;
      this.itemsExitosos = [];
      this.itemsFallidos = [];
      let arregloCorregido = [];
      this.colorProgreso = "white";
      this.loadingUpdate = true;
      this.loadingTable = true;
      let response = "";
      console.log(this.bandejaArray);

      arregloTask.map(async (data) => {
        arregloCorregido.push(data.trim());
      });

      if (this.tipoOperacion === "PICK UPS EXITOSOS") {
        this.tipoOperacionAux = "SuccessPickup";
      } else if (this.tipoOperacion === "ASIGNACION DELIVERIES") {
        this.tipoOperacionAux = "DeliveryAssignment";
      } else if (this.tipoOperacion === "ASIGNACION PICKUPS") {
        this.tipoOperacionAux = "PickupAssignment";
      } else {
        alert("Debes seleccionar todos los campos!");
        this.modalResultados = false;
        return;
      }

      if (arregloCorregido.length == 0) {
        alert("Debe ingresar las ordenes para poder avanzar");
        return;
      }

      this.loading = true;
      // console.log("Rayo " + this.rayoVirtual);

      // console.log((this.tipoOperacion = "DeliveryAssignment"));

      // this.modalResultados = true;
      let dataSent = {
        idtask: "",
        tasks: arregloCorregido,
        idRayo: this.rayoVirtual,
        multiscan_type: this.tipoOperacion,
        modifier_user: {
          email: firebase.auth().currentUser.email,
          name: firebase.auth().currentUser.displayName,
        },
        startRoute: {
          type: "rayo",
        },
        emailResponsable: this.datosUsuario().email_user,
        nameResponsable: this.datosUsuario().name_user,
        uidResponsable: this.datosUsuario().uid,
        sistemaResponsable: "warehouse.rayoapp.com",
        epochResponsable: new Date().getTime(),
      };

      try {
        response = await axios.post(
          `https://backendservices.rayoapp.com/Routes/validateRoute/?rayo=08a46637120a404a963395dd86986b4f`,
          dataSent,
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        if (response.status == 200) {
          this.validarRuta = true;
          this.loading = false;
          this.rutaPreview = response.data;
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        alert(
          `${error.response.data.error.detail.error_message}` ||
            "Ocurrio un error, revisar con TI"
        );
        return;
      }

      this.routePreview = response.data.data;

      this.tipoOperacion = this.tipoOperacion;
      try {
        if (this.localSeleccionado == "1119") {
          console.log(this.localSeleccionado);
          console.log(this.itemsExitosos);
          var pedidosCorreo = [];

          for (const i in this.itemsExitosos) {
            pedidosCorreo.push(this.itemsExitosos[i].orderIdExitoso);
          }

          let dataCorreo = {
            pedidos: pedidosCorreo,
            correos: ["felipe2889@hotmail.com"],
            emailResponsable: this.datosUsuario().email_user,
            nameResponsable: this.datosUsuario().name_user,
            uidResponsable: this.datosUsuario().uid,
            sistemaResponsable: "warehouse.rayoapp.com",
            epochResponsable: new Date().getTime(),
          };

          const respuestaCorreo = await axios.post(
            "https://us-central1-rayo-dev.cloudfunctions.net/envioCorreoRetiroFcv/EnviaEmail",
            dataCorreo
          );
          console.log(respuestaCorreo);
          this.snackbarCorreo = true;
        }
      } catch (error) {
        console.log("ERROR ENVIANDO CORREO!", error);
      }

      this.colorProgreso = "info lighten-1";
    },

    async createRoute() {
      this.validarRuta = false;
      this.loadingRoute = true;
      let response = "";
      let dataSent = {
        emailResponsable: this.datosUsuario().email_user,
        nameResponsable: this.datosUsuario().name_user,
        uidResponsable: this.datosUsuario().uid,
        sistemaResponsable: "warehouse.rayoapp.com",
        epochResponsable: new Date().getTime(),
        observaciones: "",
        idRayo: this.rayoVirtual,
        pickingRoute: false,
        routes: [
          {
            stepsCount: this.routePreview.stepsCount,
            zones: this.routePreview.zones,
            sizes: this.routePreview.sizes,
            steps: this.routePreview.steps,
            tasks: this.routePreview.tasks,
            polyline: this.routePreview.polyline,
            decodedPolyline: this.routePreview.decodedPolyline,
            googleMapsLink: this.routePreview.googleMapsLink,
            distance: this.routePreview.distance,
            duration: this.routePreview.distance,
          },
        ],
      };

      response = await axios.post(
        `https://backendservices.rayoapp.com/Routes/routesArr/?rayo=08a46637120a404a963395dd86986b4f`,
        dataSent,
        {
          headers: {
            "warehouse.rayoapp.com": "rayo",
          },
        }
      );

      if (response.status == 200) {
        this.validateLog = true;
        this.message = `Se ha logrado crear la ruta ${response.data.data[0].code_route} asignada a ${this.rayovirtual_name}`;
        this.bandeja = "";
        this.rayoVirtual = "";
        this.validarRuta = false;
        this.loadingRoute = false;
        this.modalMessage = true;
      }
    },

    cambioVehiculo(f) {
      console.log(f);
      this.vehiculo = f;
    },
    cambioWMS(s) {
      console.log(s);
      this.selectedStatusWMS = s._id;
      this.selectedStatusWMS = s;
      this.mostratWH1 = true;

      //   if (this.tipo == "Ingreso a Bodega") {
      //     this.mostratWH1 = true;
      // }
    },
    cambio(s) {
      console.log(s);
      this.tipo = s;
      if (this.tipo == "Carga vehiculo") {
        this.mostrarCargaVehiculo = true;
      } else {
        this.mostrarCargaVehiculo = false;
      }

      if (this.tipo == "Asignacion") {
        this.mostrarAsignacion = true;
      } else {
        this.mostrarAsignacion = false;
      }

      if (this.tipo == "Ingreso a Bodega") {
        this.mostratWH1 = true;
      } else {
        this.mostratWH1 = false;
      }
    },

    cambioRayoVirtual(s) {
      console.log(s);
      this.rayoVirtual = s.id;
      this.rayovirtual_name = s.text;

      for (let x in this.tasksArray) {
        for (let a in this.tasksArray[x]) {
          //console.log(this.tasksArray[x][a])
          this.tasksArray[x][a].fleet_id = s.id;
        }
      }
    },

    cambioTipoOperacion(s) {
      console.log(s);
      this.tipoOperacion = s;
    },

    setFocusBuscador() {
      // Note, you need to add a ref="search" attribute to your input.

      let self = this;
      this.$nextTick().then(function () {
        self.$refs.buscador.focus();
      });
    },
    setFocusInserta() {
      let self = this;
      this.$nextTick().then(function () {
        self.$refs.inserta.focus();
      });
    },
    //async insertaControl() {
    async insertaControl() {
      if (this.searchOrderId == "") {
        alert("Debes ingresar un ID DE ORDEN");
      } else {
        //	this.showCargando = true;

        let self = this;
        // acá vamos a consuiltar el aux y sobreescribimos el id_fcv

        let idFINAL = this.searchOrderId;
        //var superDuper =  this.ref.child("pedidosDEV")
        var superDuper = this.ref
          .child("pedidos")
          .orderByChild("aux")
          .equalTo(this.searchOrderId);

        var snapshotOK = await superDuper.once("value");

        if (
          snapshotOK.val() != null &&
          snapshotOK.val()[Object.keys(snapshotOK.val())[0]].id_fcv != null
        ) {
          idFINAL = snapshotOK.val()[Object.keys(snapshotOK.val())[0]].id_fcv;
          console.log(idFINAL);
        }

        let objetoOk = {};
        if (idFINAL != null) {
          this.searchOrderId = idFINAL;

          objetoOk = {
            uid: this.$store.state.user.data.uid,
            email: this.$store.state.user.data.email,
            id_fcv: this.searchOrderId,
            notes: this.notes,
            voluminoso: this.voluminoso,
            tipo: this.tipo,
          };
          console.log("DESPUES DE ASYNC exito aux:" + this.searchOrderId);
        } else {
          objetoOk = {
            uid: this.$store.state.user.data.uid,
            email: this.$store.state.user.data.email,
            id_fcv: this.searchOrderId,
            notes: this.notes,
            voluminoso: this.voluminoso,
            tipo: this.tipo,
            emailResponsable: this.datosUsuario().email_user,
            nameResponsable: this.datosUsuario().name_user,
            uidResponsable: this.datosUsuario().uid,
            sistemaResponsable: "warehouse.rayoapp.com",
            epochResponsable: new Date().getTime(),
          };
          console.log("DESPUES DE ASYNC fail aux:" + this.searchOrderId);
        }

        // ff acá vamos a consuiltar el aux y sobreescribimos el id_fcv

        let tipoIngreso = this.tipo;

        switch (tipoIngreso) {
          case "Escaneo en sucursal":
            objetoOk.voluminoso = this.isVoluminoso;
            break;

          case "Pedido no listo para retiro":
            objetoOk.notes = this.franjaHora;
            break;

          default:
            break;
        }

        function go(paramss) {
          axios
            .post(
              "https://us-central1-rayo-dev.cloudfunctions.net/retirosLocalSocofar",
              objetoOk
            )
            .then(function (response) {
              if (paramss == 0) {
                objetoOk.estado = "EXITO";
                self.datosScanner.push(objetoOk);

                self.snackText = response.data;
                self.snackbar = true;

                self.showCargando = false;
                console.log(response);
                console.log(response);
                self.snackbar = true;
                self.modalStatus = false;
                self.searchOrderId = "";
                self.setFocusBuscador();
              } else {
                axios
                  .post("CONSIGUE TASK_ID ", objetoOk)
                  .then(function (response) {
                    objetoOk.estado =
                      "EXITO REGISTRO Y EXITO EN ASSIGNACION HAY QUE CAMBIAR EL TIPO O NO INSTERAR EN SQL";
                    self.datosScanner.push(objetoOk);

                    self.snackText = response.data;
                    self.snackbar = true;

                    self.showCargando = false;
                    console.log(response);
                    console.log(response);
                    self.snackbar = true;
                    self.modalStatus = false;
                    self.searchOrderId = "";
                    self.setFocusBuscador();
                  })
                  .catch(function (err) {
                    objetoOk.estado =
                      "EXITO REGISTRO Y FALLO EN ASSIGNACION HAY QUE CAMBIAR EL TIPO O NO INSTERAR EN SQL";
                    self.datosScanner.push(objetoOk);

                    self.snackText = response.data;
                    self.snackbar = true;

                    self.showCargando = false;
                    console.log(response);
                    console.log(response);
                    self.snackbar = true;
                    self.modalStatus = false;
                    self.searchOrderId = "";
                    self.setFocusBuscador();
                  });
              }

              self.isVoluminoso = false;
            })
            .catch(function (error) {
              objetoOk.estado = "ERROR";
              self.datosScanner.push(objetoOk);

              console.log(error);
              self.snackText =
                "Error en el registro. Contacta por favor al equipo de Rayo IT";
              self.snackbar = true;
              console.log("catch axios: ", error);
              self.showCargando = false;
              self.modalStatus = false;
              self.searchOrderId = "";
              self.setFocusBuscador();
              self.isVoluminoso = false;

              // self.contenidoModal = self.contenidoModalNotOk
              //  self.dialog = true
            });
        }

        if (self.tipo != "") {
          if (self.mostrarAsignacion && self.rayoVirtual == "") {
            alert("Debes seleccionar un Rayo Virtual");
            this.showCargando = false;
          } else if (!self.mostrarAsignacion) {
            go(0);
            /// **************** descomentar 0 y 1
          } else {
            go(1);
            /// **************** descomentar 0 y 1
          }
        } else {
          alert("Debes seleccionar tipo de operación");
          this.showCargando = false;
        }
      }
    },
    async insertaControlWH1() {
      if (this.searchOrderId == "") {
        alert("Debes ingresar un ID DE ORDEN");
      } else {
        //	this.showCargando = true;

        let self = this;
        // acá vamos a consuiltar el aux y sobreescribimos el id_fcv

        let idFINAL = this.searchOrderId;
        //var superDuper =  this.ref.child("pedidosDEV")
        var superDuper = this.ref
          .child("pedidos")
          .orderByChild("aux")
          .equalTo(this.searchOrderId);

        var snapshotOK = await superDuper.once("value");

        if (
          snapshotOK.val() != null &&
          snapshotOK.val()[Object.keys(snapshotOK.val())[0]].id_fcv != null
        ) {
          idFINAL = snapshotOK.val()[Object.keys(snapshotOK.val())[0]].id_fcv;
          console.log(idFINAL);
        }

        let objetoOk = {};
        if (idFINAL != null) {
          this.searchOrderId = idFINAL;

          objetoOk = {
            uid: this.$store.state.user.data.uid,
            email: this.$store.state.user.data.email,
            id_fcv: this.searchOrderId,
            notes: this.notes,
            voluminoso: this.voluminoso,
            tipo: this.tipo,
          };
          console.log("DESPUES DE ASYNC exito aux:" + this.searchOrderId);
        } else {
          objetoOk = {
            uid: this.$store.state.user.data.uid,
            email: this.$store.state.user.data.email,
            id_fcv: this.searchOrderId,
            notes: this.notes,
            voluminoso: this.voluminoso,
            tipo: this.tipo,
          };
          console.log("DESPUES DE ASYNC fail aux:" + this.searchOrderId);
        }

        // ff acá vamos a consuiltar el aux y sobreescribimos el id_fcv

        let tipoIngreso = this.tipo;

        switch (tipoIngreso) {
          case "Escaneo en sucursal":
            objetoOk.voluminoso = this.isVoluminoso;
            break;

          case "Pedido no listo para retiro":
            objetoOk.notes = this.franjaHora;
            break;

          default:
            break;
        }

        function go(paramss) {
          axios
            .post(
              "https://us-central1-rayo-dev.cloudfunctions.net/retirosLocalSocofar",
              objetoOk
            )
            .then(function (response) {
              if (paramss == 0) {
                objetoOk.estado = "EXITO";
                self.datosScanner.push(objetoOk);

                self.snackText = response.data;
                self.snackbar = true;

                self.showCargando = false;
                console.log(response);
                console.log(response);
                self.snackbar = true;
                self.modalStatus = false;
                self.searchOrderId = "";
                self.setFocusBuscador();
              } else {
                axios
                  .post("CONSIGUE TASK_ID ", objetoOk)
                  .then(function (response) {
                    objetoOk.estado =
                      "EXITO REGISTRO Y EXITO EN ASSIGNACION HAY QUE CAMBIAR EL TIPO O NO INSTERAR EN SQL";
                    self.datosScanner.push(objetoOk);

                    self.snackText = response.data;
                    self.snackbar = true;

                    self.showCargando = false;
                    console.log(response);
                    console.log(response);
                    self.snackbar = true;
                    self.modalStatus = false;
                    self.searchOrderId = "";
                    self.setFocusBuscador();
                  })
                  .catch(function (err) {
                    objetoOk.estado =
                      "EXITO REGISTRO Y FALLO EN ASSIGNACION HAY QUE CAMBIAR EL TIPO O NO INSTERAR EN SQL";
                    self.datosScanner.push(objetoOk);

                    self.snackText = response.data;
                    self.snackbar = true;

                    self.showCargando = false;
                    console.log(response);
                    console.log(response);
                    self.snackbar = true;
                    self.modalStatus = false;
                    self.searchOrderId = "";
                    self.setFocusBuscador();
                  });
              }

              self.isVoluminoso = false;
            })
            .catch(function (error) {
              objetoOk.estado = "ERROR";
              self.datosScanner.push(objetoOk);

              console.log(error);
              self.snackText =
                "Error en el registro. Contacta por favor al equipo de Rayo IT";
              self.snackbar = true;
              console.log("catch axios: ", error);
              self.showCargando = false;
              self.modalStatus = false;
              self.searchOrderId = "";
              self.setFocusBuscador();
              self.isVoluminoso = false;

              // self.contenidoModal = self.contenidoModalNotOk
              //  self.dialog = true
            });
        }

        if (self.tipo != "") {
          if (self.mostratWH1 && self.rayoVirtual == "") {
            alert("Debes seleccionar un Rayo Virtual");
            this.showCargando = false;
          } else if (!self.mostratWH1) {
            go(0);
            /// **************** descomentar 0 y 1
          } else {
            go(1);
            /// **************** descomentar 0 y 1
          }
        } else {
          alert("Debes seleccionar tipo de operación");
          this.showCargando = false;
        }
      }
    },
    accionCreaToken(dataCreate) {
      let selfa = this;
      //console.log(this.pedidosTokenPath);

      // acá debemos seleccionar el mayo tiempo de  los tokens y luego seleccionar solo uno.

      let keyToUpdate = Object.keys(dataCreate)[0];

      let firstData = dataCreate[keyToUpdate];

      console.log(firstData);

      // vars

      let paymentMethod =
        firstData.pickups != undefined &&
        firstData.pickups[0].template_data[1] != undefined
          ? firstData.pickups[0].template_data[1]
          : "";
      let ejecutivo =
        firstData.pickups != undefined &&
        firstData.pickups[0].template_data[3] != undefined
          ? firstData.pickups[0].template_data[3]
          : "";
      let comentarios =
        firstData.pickups != undefined &&
        firstData.pickups[0].template_data[4] != undefined
          ? firstData.pickups[0].template_data[4]
          : "";

      let retiroReceta =
        firstData.pickups != undefined &&
        firstData.pickups[0].template_data[6] != undefined
          ? firstData.pickups[0].template_data[6]
          : "";

      let convenioEmpresa =
        firstData.pickups != undefined &&
        firstData.pickups[0].template_data[7] != undefined
          ? firstData.pickups[0].template_data[7]
          : "";

      let referenciaDireccion =
        firstData.pickups != undefined &&
        firstData.pickups[0].template_data[8] != undefined
          ? firstData.pickups[0].template_data[8]
          : "";

      let creacionCC =
        firstData.pickups != undefined &&
        firstData.pickups[0].template_data[9] != undefined
          ? firstData.pickups[0].template_data[9]
          : "";

      // f vars
      if (firstData.pickups != undefined && firstData.pickups[0] != undefined) {
        this.ref
          .child("pedidos/" + keyToUpdate + "/tookan1/mainstatus/")
          .update({
            status: "Preparada",
            momentReady: firebase.database.ServerValue.TIMESTAMP,
          })
          .then(() => {
            console.log("Document data updated to Preparada");
            //console.log(dataCreate.detalle);
            // var xx = Object.keys(dataCreate.detalle).length - 1;
            var arrPromises = [];
            // for (var x in dataCreate.detalle) {
            // console.log(x);
            var self = selfa;

            axios
              .post(
                "https://us-central1-panel-53aab.cloudfunctions.net/create_task_clean",

                {
                  emailResponsable: this.datosUsuario().email_user,
                  nameResponsable: this.datosUsuario().name_user,
                  uidResponsable: this.datosUsuario().uid,
                  sistemaResponsable: "warehouse.rayoapp.com",
                  epochResponsable: new Date().getTime(),
                  api_key:
                    "53636181f9405f455f57723d5610214015e1c6f222de7a3b541b",
                  // fleet_id: 19750,
                  timezone: 240,
                  has_pickup: 1,
                  has_delivery: 1,
                  layout_type: 0,
                  geofence: 1,

                  auto_assignment: 1,
                  tags: firstData.tags,
                  pickups: [
                    {
                      address: firstData.pickups[0].address,

                      time: self.momento(
                        firebase.database.ServerValue.TIMESTAMP
                      ),
                      phone: firstData.pickups[0].phone,
                      template_name: "CRUZ-VERDE-ECOMMERCE",
                      template_data: [
                        {
                          label: PAYMENT - TYPE - METHOD,
                          data: paymentMethod,
                        },
                        {
                          label: "COLLECTION-AMOUNT-CURRENCY",
                          data: "",
                        },
                        {
                          label: "PRODUCT-LIST",
                          data: "",
                        },
                        {
                          label: "creacioncc",
                          data: creacionCC,
                        },
                        {
                          label: "origen",
                          data: "Cambio de estado en Datasocofar",
                        },
                        {
                          label: "idfcv",
                          data: firstData.id_fcv,
                        },
                        {
                          label: "idsucursal",
                          data: firstData.tags,
                        },
                      ],
                      name: firstData.pickups[0].name,
                      email: firstData.pickups[0].email,
                      order_id: firstData.id_fcv,
                      job_description: firstData.pickups[0].job_description,
                    },
                  ],
                  deliveries: [
                    {
                      address: firstData.deliveries[0].address,

                      time: self.momento(
                        firebase.database.ServerValue.TIMESTAMP
                      ),
                      phone: firstData.deliveries[0].phone,
                      template_name: "CRUZ-VERDE-ECOMMERCE",
                      template_data: [
                        {
                          label: PAYMENT - TYPE - METHOD,
                          data: paymentMethod,
                        },
                        {
                          label: "COLLECTION-AMOUNT-CURRENCY",
                          data: "",
                        },
                        {
                          label: "PRODUCT-LIST",
                          data: "",
                        },
                        {
                          label: "creacioncc",
                          data: creacionCC,
                        },
                        {
                          label: "origen",
                          data: "Cambio de estado en Datasocofar",
                        },
                        {
                          label: "idfcv",
                          data: firstData.id_fcv,
                        },
                        {
                          label: "idsucursal",
                          data: firstData.tags,
                        },
                      ],
                      name: firstData.deliveries[0].name,
                      email: firstData.deliveries[0].email,
                      order_id: firstData.id_fcv,
                      job_description: firstData.deliveries[0].job_description,
                    },
                  ],
                }
              )
              .then(function () {
                // const responseOne = responses[0];
                // const responseTwo = responses[1];
                // const responesThree = responses[2];
                this.modalCancel = false;
                this.snackText = "La tareas han sido creadas en operaciones...";
                this.snackbar = true;
                //  console.log(responses);
                // use/access the results
              })
              .catch((errors) => {
                console.log(errors);
              });
          })
          .catch((err) => {
            this.modalCancel = false;
            console.log("Error en la asignación de las tareas", err);
            this.snackbar = true;
            this.snackText =
              "Se ha producido un error en la asignación de las tareas.";
          });
      } else {
        console.log("Error en la DEFINICIÓN de las tareas");
        this.snackbar = true;
        this.snackText =
          "Se ha producido un error en la DEFINICIÓN de la tarea. Por favor contacta al equipo de T.I  de Rayo.";
      }
    },

    scanId() {
      //this.showCargando = true
      console.log(this.searchOrderId);

      //var self = this;

      //self.showCargando = false
      this.modalStatus = true;

      //   this.setFocusInserta()
    },
    // seleccionaStatus (cambio) {
    //   console.log(cambio)
    //   this.selectedStatus = cambio
    // },

    snackIt(sms) {
      this.snackText = sms;
      this.snackbar = true;
    },
    momento(x) {
      return moment(x).format("YYYY-MM-DD HH:mm");
    },

    momentum(x) {
      return moment(x);
    },

    momentoMilis(x) {
      console.log(moment(x).format("DD-MM-YYYY:HH:mm"));
      console.log(moment(x).format("DD-MMM-YYYY:HH:mm"));
      console.log(moment(x).format("x"));
      return moment(x).format("x");
    },

    async getRayosList() {
      let self = this;

      self.idProvider = await self.getProviderId();

      try {
        self.loading = true;
        let arrayFleets = [];
        const listaRayos = await axios.get(
          "https://backendservices.rayoapp.com/Rayos/rayos/reduced?vigencia=true&rayo=08a46637120a404a963395dd86986b4f",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        // console.log(listaRayos.status);

        if (self.user.data.tipoUsuario == "rayo") {
          for (const n in listaRayos.data) {
            if (listaRayos.data[n].vigencia == true) {
              arrayFleets.push({
                text: listaRayos.data[n].name_rayo,
                id: listaRayos.data[n]._id,
              });
            }
          }
        } else {
          // const proveedores = await axios.get("https://backendservices.rayoapp.com/Providers/providers");
          // for (const n in proveedores.data) {
          //   console.log(proveedores.data[n].id);
          // }
          console.log("Obteniendo Rayos del proveedor " + self.idProvider);
          // console.log("Epa Rayo: " + listaRayos.data[0].provider_rayo);
          // console.log("Id EPA: " + this.idProvider);
          for (const n in listaRayos.data) {
            if (
              listaRayos.data[n].provider_rayo === self.idProvider &&
              listaRayos.data[n].vigencia == true
            ) {
              // console.log("Obteniendo Rayos del proveedor...");
              arrayFleets.push({
                text: listaRayos.data[n].name_rayo,
                id: listaRayos.data[n].id,
              });
            }

            // let self = this;
            // // let selectedAccountKey =
            // //   "28c59a186592ea55f8849455f0a16933c19e39db3fde6303eacfcc81eff9f0aa";

            // self.rayosVirtuales = [];

            // axios
            //   .get("https://backendservices.rayoapp.com/Rayos/rayos", {
            //     // api_key: selectedAccountKey,
            //   })
            //   .then(function(response) {
            //     let arrayFleets = [];
            //     let arrayResponse = response.data;
            //     console.log(arrayResponse);

            //     arrayResponse.map((item, index) => {
            //       // let esFCV = false
            //       for (let a in item.customer_rayo) {
            //         if (
            //           item.customer_rayo[a].idCustomer ==
            //             "5f0c95b1495f530ee0dbe9ff" ||
            //           item.customer_rayo[a].idCustomer ==
            //             "5fbe4f9f48cdb0e97b84ac48" ||
            //           item.customer_rayo[a].idCustomer ==
            //             "5f0c954e495f530ee0dbe9fe"
            //         ) {
            //           item.esFCV = true;
            //           //  (())   esFCV = true
            //           break;
            //         }
            //       }
            //       // filtra por cliente
            //       // if (item.esFCV === true) {
            //       arrayFleets.push({
            //         text: item.name_rayo.toUpperCase(),
            //         id: item.id,
            //       });
            //       // }
            //     });

            //     arrayFleets.sort((b, a) => b.text.localeCompare(a.text));

            //     self.rayosVirtuales = arrayFleets;
            //   })
            //   .catch(function(error) {
            //     console.log("Error al obtener Rayos", error);
            //   });
            //   break;
          }
        }
        console.log("Lista creada!");

        arrayFleets.sort((b, a) => b.text.localeCompare(a.text));
        self.rayosVirtuales = arrayFleets;

        self.loading = false;
        rayosVirtuales;
      } catch (error) {
        console.log(error);
      }
    },
    async getProviderId() {
      let self = this;
      let selectedAccountKey =
        "28c59a186592ea55f8849455f0a16933c19e39db3fde6303eacfcc81eff9f0aa";

      self.rayosVirtuales = [];
      let self2 = self;

      try {
        let response = await axios.get(
          "https://backendservices.rayoapp.com/Providers/providers/?rayo=08a46637120a404a963395dd86986b4f",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        let arrayResponse = response.data.filter(
          (h) => h.email_provider == self2.user.data.email
        );
        console.log(self2.user.data.email);
        // this.correoIngreso = self.user.data.email;
        // console.log(this.correoIngreso);
        return arrayResponse[0]._id;

        //
      } catch (error) {
        console.log("Error al obtener Provider", error);
      }
    },
    getStatusList() {
      let self = this;
      let selectedAccountKey =
        "28c59a186592ea55f8849455f0a16933c19e39db3fde6303eacfcc81eff9f0aa";

      self.estados = [];

      axios
        .get(
          "https://backendservices.rayoapp.com/Status/all_status?rayo=08a46637120a404a963395dd86986b4f",
          {
            headers: {
              "tools.rayoapp.com": "rayo",
            },
          }
        )
        .then(function (response) {
          let arrayStatus = [];
          let arrayResponse = response.data;

          arrayResponse.map((item, index) => {
            // if (item.is_active === 1) {
            arrayStatus.push(item);
            // }
          });

          arrayStatus.sort((a, b) =>
            b.name_status.localeCompare(a.name_status)
          );

          self.estados = arrayStatus;
        })
        .catch(function (error) {
          console.log("Error al obtener Status", error);
        });
    },
    getWMSStatusesList() {
      let self = this;
      self.statusesWMS = [];

      axios
        .post(
          "https://us-central1-rayo-api.cloudfunctions.net/mongoWarehouse/getStatusesWMS",
          {}
        )
        //.post("http://localhost:8080/getStatusesWMS", {})
        .then(function (response) {
          let arrayStatusWMS = [];
          let arrayResponse = response.data;

          arrayResponse.map((item, index) => {
            // if (item.is_active === 1) {
            arrayStatusWMS.push(item);
            // }
          });

          arrayStatusWMS.sort((a, b) =>
            a.name_status.localeCompare(b.name_status)
          );

          self.statusesWMS = arrayStatusWMS;
        })
        .catch(function (error) {
          console.log("Error al obtener Status", error);
        });
    },
    iconURL(typeLocationIn, mNumber, mColor) {
      // console.log(typeLocationIn)
      switch (typeLocationIn) {
        case "start":
          return {
            icono: L.icon({
              iconUrl: "https://rayoapp.com/img/rayop.png",
              iconAnchor: this.ancla,
              iconSize: [40, 40],
            }),
            draggable: false,
          };

        case "end":
          return {
            icono: L.icon({
              iconUrl: "https://rayoapp.com/img/rayod.png",
              iconAnchor: [17, 36],
              iconSize: [40, 40],
              draggable: false,
            }),
            draggable: false,
          };
        case "bodega":
          return {
            icono: L.icon({
              iconUrl: "https://rayoapp.com/img/rayop.png",
              iconAnchor: [17, 36],
              iconSize: [40, 40],
            }),
            draggable: false,
          };
        case "job":
          // console.log(mColor);
          return {
            // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="60" width="40">
            // <g>
            //   <title>Layer 1</title>
            //   <path stroke="#311eee" d="m2.98429,3.7029c-1.49166,1.59197 -2.42599,3.91104 -2.38971,6.09236c0.07576,4.55831 2.13042,6.28386 5.4162,12.55752c1.1836,2.76066 2.41892,5.68179 3.59382,10.5198c0.16328,0.71364 0.32252,1.37652 0.39618,1.43395c0.07363,0.0575 0.2329,-0.60776 0.39618,-1.3214c1.1749,-4.83801 2.41022,-7.75682 3.59382,-10.51748c3.28578,-6.27366 5.34042,-7.99922 5.4162,-12.55753c0.03628,-2.18132 -0.90036,-4.50269 -2.39202,-6.09467c-1.70398,-1.81856 -4.2741,-3.16443 -7.01418,-3.22071c-2.74008,-0.05632 -5.31251,1.2896 -7.01649,3.10817l0,-0.00001z" fill="#ffffff" id="svg_3"/>
            //   <circle r="5" cy="56.81754" cx="135.94733" id="path3049" fill="` + mColor + `" transform="matrix(1.18559 0 0 1.18559 -151.177 -57.3976)"/>
            //   <text fill="#ffffff" x="13.80749" y="-4.96197" id="svg_4" stroke-width="0" font-size="26" font-family="'Noto Sans Mono'" text-anchor="start" xml:space="preserve" stroke="null" transform="matrix(0.276176 0 0 0.276176 4.33532 14.1585)">` + mNumber + `</text>
            //  </g>

            // </svg>
            icono: L.divIcon({
              iconAnchor: this.ancla,
              iconSize: this.iconSize,
              className: "my-custom-pin",
              html:
                `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="60" width="40">
  <g>
  <title>Layer 1</title>
  <circle stroke="#000000" transform="matrix(1.18559 0 0 1.18559 -151.177 -57.3976)" fill="` +
                mColor +
                `" id="path3049" cx="135.94733" cy="56.81754" r="5"/>
  <text transform="matrix(0.276176 0 0 0.276176 4.33532 14.1585)" stroke="null" xml:space="preserve" text-anchor="start" font-family="'Roboto,sans-serif'" font-size="27" stroke-width="0" id="svg_4" y="-5.51902" x="12.13632" fill="#000000">` +
                mNumber +
                `</text>
  <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_1" y2="26.1396" x2="10.30769" y1="15.83079" x1="10.30769" stroke="#000000" fill="none"/>
 </g>

</svg>`,
            }),
          };
        // return {
        //   icono: L.icon({
        //     iconUrl:
        //       'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_white' +
        //       mNumber +
        //       '.png',
        //     iconSize: [22, 40]
        //   }),
        //   draggable: false
        // }
        case "iniFin":
          console.log("INIFIN");
          return {
            icono: L.icon({
              iconAnchor: [17, 36],
              iconSize: [40, 40],
              //  iconUrl: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue' + mNumber + '.png',
              iconUrl:
                // 'https://maps.google.com/mapfiles/kml/paddle/blu-square.png',
                "https://rayoapp.com/img/rayod.png",
            }),
            draggable: true,
          };
      }
      return true;
    },
    crearMapa(coordenadas, steps) {
      // console.log("COOR:", coordenadas);
      // console.log("STEPS:", steps);

      this.markersDialogo = [];
      this.stepsRuta = steps;
      // console.log("stepsRuta", this.stepsRuta);
      // console.log(this.stepsRuta[0].location);
      // console.log(this.stepsRuta[1].location);
      this.dialogoMapa = true;
      // console.log("Coordenadas", coordenadas);
      var coord = polyUtil.decode(coordenadas);

      for (let i in steps) {
        //  console.log(steps[i].location[0].$numberDouble)
        this.markersDialogo.push(
          L.latLng(steps[i].location[1], steps[i].location[0])
        );
      }
      // console.log("Polyline decodificado");
      // console.log(coord);
      this.arrayCoordenadas = coord;
    },
  },

  async mounted() {
    this.loadingData = true;
    this.tsfin = parseInt(moment(new Date()).format("x"));

    this.getName(765969);
    this.setFocusBuscador();
    this.getRayosList();
    this.getStatusList();
    this.getWMSStatusesList();
  },
};
</script>
